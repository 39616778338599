export default{
    props:{
        visible: Boolean,
        data:{
            type: Array,
            default: () => []
        },
        type: Number
    },
    data(){
        return{
            show: false,
            radioVal: null
        }
    },
    computed: {
        list(){
            let { data, type } = this;
            if(type == 10){
                return data.filter(i => i.purchaseType == 1)
            }
            else return data.filter(i => i.purchaseType == 2)
        }
    },
    watch:{
        visible(val){
            this.show = val
        }
    },
    methods:{
        // 选择
        onSelect(index){
            this.radioVal = index
        },
        onClose(){
            this.radioVal = null
            this.$emit('close')
        },
        onConfirm(){
            let { list, radioVal } = this;

            if(radioVal === null) return
            this.$emit('confirm', list[parseInt(radioVal)])
            this.$emit('close')
        }
    }
}