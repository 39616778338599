import { mapState } from 'vuex';
import config from './config';
import TourismPackages from './components/tourismPackages/index.vue'
import DatePicker from './components/date-picker/index.vue'
import AddressPicker from "@/components/common/address-picker/index.vue";
import { getProductList, signup } from '@/service/enroll-activity/index';
import { verifyIdentityCard, verifyTel, verifyFlightCode } from '@/utils/verify'

let formValDefalut = {...config.formVal}
export default{
    components:{
        TourismPackages,
        AddressPicker,
        DatePicker
    },
    data(){
        return{
            formList: config.formList,
            formVal: formValDefalut,
            showTourism: false,
            showDatePicker: false,
            openAddressType: '',
            dateType: '',
            packagesList: [], //活动套餐列表
            directJuniorsList: [], // 直属下级
            signupId: '', // 活动id
            signupProductId: '', // 套餐id
        }
    },
    computed:{
        ...mapState({
            userId: state => state.user.user.id
        }),
    },
    
    mounted(){
        let { formVal } = this;
        let { signupId } = this.$route.query;
        this.signupId = signupId;
        this.getProduct()

        // 监听返回传参
        this.$bus.$on('agentInfo', ({ id, name, identity, phone }) =>{
            this.formVal.agent = name;
            this.formVal.bindUserId = id;

            // 判断是否为智能填写 
            if(formVal.writeFill == 1){
                this.formVal.name = name;
                this.formVal.phone = phone;
                this.formVal.identity = identity;
            }
        })
    },
    methods:{
        // 报名管理套餐查询
        async getProduct(){
            let { signupId } = this
            let res = await getProductList({ mainId: signupId })
            this.packagesList = res.list
        },
        
        // 点击更多
        onMore(item){
            let { signupId } = this;
            switch(item.name){
                case 'tourismPackages': this.showTourism = true; break;
                case 'agent': {
                    this.$router.push({
                        name: 'enroll-agent',
                        query: { signupId }
                    })
                } break;
            }
        },
        // 打开日期餐modal
        onDate(name){
            this.dateType = name
            this.showDatePicker = true
        },
        // 点击套餐确定 
        onConfirmPackages(val){
            this.formVal.tourismAmount = val.actPrice
            this.formVal.tourismPackages = val.productName
            this.formVal.signupProductId = val.id
        },
        // 打开地址
        showAreaPopup(type) {
            this.openAddressType = type
            this.$refs["addressPicker"].open();
        },
        // 选择地址回调
        onAddressChange(val){
            let { openAddressType } = this;
            this.formVal[openAddressType+'Name'] = val.city.name
            this.formVal[openAddressType+'Id'] = val.city.id
        },
        // 点击日期
        onConfirmDate(date){
            let { dateType } = this
            const d = new Date(date)

            const month = (d.getMonth()+1) > 9? (d.getMonth()+1) : ('0'+(d.getMonth()+1))
            const minutes = (d.getMinutes()) > 9? (d.getMinutes()) : ('0'+d.getMinutes())
            const day = (d.getDate()) > 9? (d.getDate()) : ('0'+d.getDate())

            this.formVal[dateType] = `${d.getFullYear()}-${month}-${day} ${d.getHours()}:${minutes}`
        },
        onRadio(name, val){
            this.formVal[name] = val

            // 点击切换报名类型
            if(name == "type"){
                this.formVal.tourismPackages = ''
                this.formVal.signupProductId = ''
            }
            // 智能填写 选择 否
            if(name == 'writeFill' && val == 2 ){
                this.formVal.name = ''
                this.formVal.phone = ''
                this.formVal.identity = ''
            }
        },
        // 点击提交
        async onSubmit(){
            let { formVal, userId, signupId } = this;
            formVal.userId = userId

            let params = { ...formVal, mainId: signupId }, isCom = true
            //if(Object.values(params).filter(i => i === '').length > 0) isCom = false

           // if(!isCom) return this.$toast('请填写完整报名信息')
            // 身份证号码 
            if(!verifyIdentityCard(params.identity)) return

            //for(let i in params){
              //  if(['goDepartureTime', 'goArrivalTime', 'backDepartureTime'].includes(i)){
                //    params[i] = params[i] + ':00'
               // }
          //  }

            const res = await signup(params)
            
            formValDefalut = {...config.formVal} // 重置表单
            // 免费的 
            if(params.type == 10){
                this.$router.push({ name: 'enroll-success-info' })
            }
            // 支付的
            else{
                this.$router.push({ 
                    name: 'enroll-wxPay', 
                    query: { 
                        amount: params.tourismAmount,
                        name: params.name,
                        identity: params.identity,
                        tourismPackages: params.tourismPackages,
                        code: res.code
                    }
                })
            }
            
            return false;
        }
    }
}