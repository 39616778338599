export default{
    props:{
        visible: Boolean
    },
    data(){
        return{
            show: false
        }
    },
    watch:{
        visible(val){
            this.show = val
        }
    },
    methods:{
        onClose(){
            this.$emit('close')
        },
        onConfirmDate(picker){
            this.$emit('confirm', picker)
            this.$emit('close')
        }
    }
}