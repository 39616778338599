export default {
    formList:[
        {
            type: 'radio',
            label: '报名类型',
            name: 'type',
            require: true,
            icon: 'we-1',
            muster: [
                { name: '400盒名额', value: 10 },
                { name: '活动礼包名额', value: 20 }
            ]
        },
        {
            type: 'more',
            name: 'tourismPackages',
            label: '旅游套餐',
            icon: 'we-10',
            require: true
        },
        {
            type: 'more',
            name: 'agent',
            label: '选择代理(家属选跟随代理)',
            icon: 'we-10',
            require: true
        },
        {
            type: 'radio',
            label: '代理选是',
            name: 'writeFill',
            require: true,
            icon: 'we-11',
            muster: [
                { name: '是', value: 1 },
                { name: '否(家属选否)', value: 2 }
            ]
        },
        {
            type: 'text',
            name: 'name',
            label: '旅游人姓名',
            icon: 'we-9',
            require: true
        },
        {
            type: 'number',
            name: 'phone',
            label: '手机号码',
            icon: 'we-8',
            require: true
        },
        {
            type: 'idCard',
            name: 'identity',
            label: '身份证',
            icon: 'we-7',
            require: true
        },
        {
            type: 'text',
            name: 'contactName',
            label: '紧急联系人名字',
            icon: 'we-5',
            require: true
        },
        {
            type: 'text',
            name: 'contactPhone',
            label: '紧急联系人电话',
            icon: 'we-8',
            require: true
        }
    ],
    formVal:{
        type: 10,
        tourismPackages: '',
        signupProductId: '',
        bindUserId: '',
        writeFill: 1,
        name: '',
        phone: '',
        identity: '',
        contactName: '',
        contactPhone: '',
        goFlightNumber: '',
        goOriginCityName: '',
        goOriginCityId: '',
        goDestCityName: '',
        goDestCityId: '',
        goDepartureTime: '',
        goArrivalTime: '',
        backFlightNumber: '',
        backOriginCityName: '',
        backOriginCityId: '',
        backDestCityName: '',
        backDestCityId: '',
        backDepartureTime: ''
    }
}
